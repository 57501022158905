<template>
  <div class="env-page">
    <a-layout>
      <a-layout-header class="page__header">老玩客運動服務狀態</a-layout-header>

      <a-layout-content class="page__content">
        <!-- <div class="">
          <span>前端系統：</span>
          <template v-if="env == 'production'">正式環境 v1.4</template>
          <template v-if="env == 'development'">測試環境 v1.4</template>
        </div> -->

        <div class="">
          <span>系統版本：</span>
          <template v-if="apiEnv.env == 'production'">正式環境 {{ apiEnv.version }}</template>
          <template v-if="apiEnv.env == 'development'">測試環境 {{ apiEnv.version }}</template>
        </div>
      </a-layout-content>
    </a-layout>
    
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import api from '@/apis'

export default defineComponent({
  components: {},
  setup() {
    const apiEnv = ref({});

    /* Api */
    const getApiEnv = (async () => {
      const response = await api.v1.env.getEnv();
      apiEnv.value = response.data.result;
    });

    /* Mounted */
    onMounted(async () => {
      await getApiEnv();
    });
    
    return {
      apiEnv
    }
  },
  computed: {
    env() {
      if (process.env.VUE_APP_ENV == 'production') return 'production'
      else if (process.env.VUE_APP_ENV == 'development') return 'development'
    }
  },
  methods: {

  }
})
</script>

<style lang="scss">
.env-page {
  .page__header {
    color: white;
    font-size: 1.1rem;
    letter-spacing: 0.05rem;
    font-weight: 500;
    padding: 0 12px;
    height: 60px;
  }

  .page__content {
    background: white;
    padding: 12px;
  }
}
</style>